<template>
  <ColorCard />
</template>

<script>
import ColorCard from '@/components/ColorCard';

export default {
  name: 'App',
  components: {
    ColorCard,
  }
}
</script>

<style lang="scss">
@use './assets/scss/app.scss';
</style>
