import { createApp } from 'vue'
import App from './App.vue'

if('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/assets/js/sw.js')
    .then((result) => {
      console.log('Service Worker registered', result);
    })
    .catch((error) => console.error(error));
}

createApp(App).mount('#app')
