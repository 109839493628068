<template>
  <div id="color-card">
    <header>
      <h1>Deep Pink</h1>
    </header>
    <main>
      <dl>
        <dt>Name</dt>
        <dd>deeppink</dd>
        <dt>Hex</dt>
        <dd>#ff1493</dd>
        <dt>RGB</dt>
        <dd>255, 20, 147</dd>
        <dt>HSL</dt>
        <dd>0.91, 1.0, 0.54</dd>
        <dt>CMYK</dt>
        <dd>0.0, 0.92, 0.42, 0.0</dd>
      </dl>
    </main>
  </div>
</template>
